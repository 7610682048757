import React from "react";
import {ReactComponent as Play} from "./play.svg"
import {ReactComponent as Pause} from "./pause.svg"
import {ReactComponent as LikeButton} from "./like.svg";
import 'swiper/css';
import 'swiper/css/free-mode';
import './MiniPlayer.css'

export default function MiniPlayer({currentTrack, audioRef, isPlaying, setIsPlaying, setOpenPlayer}){
    const handlePlay = () => {
        audioRef.current.play();
        setIsPlaying(true);
    };
    
    const handlePause = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };
    
    const handlePlayPause = () => {
        if (isPlaying) {
          handlePause();
        } else {
          handlePlay();
        }
    };

    const openPlayer = () => {
        setOpenPlayer(true);
    }

    return(
        <section className="mini-player-container">
            <div className="mini-player">
                <button className="mini-player-to-player" onClick={openPlayer}>
                    <img src = {require(`./image/${currentTrack.image}`)} className="mini-player-image"/>
                    <div className="mini-player-info">
                        <div className="mini-player-info-title">
                            {currentTrack.title}
                        </div>
                        <div className="mini-player-info-artist">
                            {currentTrack.artist}
                        </div>
                    </div>
                </button>
                <button className="mini-player-like">
                    <LikeButton height = "65%" width = "70%" />
                </button>
                <button className="mini-player-play-pause" onClick={handlePlayPause}>
                    {isPlaying ? <Pause height = "80%" width = "80%"/> : <Play height = "60%" width = "60%"/>}
                </button>
            </div>
      </section>
    )
}