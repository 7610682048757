import './App.css';
import {useState, useRef, useEffect} from "react";
import {ReactComponent as Search} from "./search.svg";
import {ReactComponent as MenuButton} from "./menu.svg";
import {ReactComponent as BackButton} from "./back.svg";
import AudioPlayer from "./AudioPlayer";
import Track from "./TrackList";
import AlbumList from "./AlbumList";
import TopArtists from './TopArtists';
import MiniPlayer from './MiniPlayer';

export default function App() {
  const backClick = () => {
    setOpenAlbum(false);
  }

  const [data, setData] = useState(null);

  /*useEffect(() => {
    fetch('/api/data.php')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      return response.json();
    })
    .then(data => setData(data))
    .catch(error => console.error('Error fetching data:', error));
  }, []);*/

  const tracks = [
    {key: 'Jdkksgfkfk', title: 'выывыв', artist: 'ываыва', href: 'https://cdn1.suno.ai/5c1487b9-89f6-49b6-afee-4f1471267f14.mp3', image: 'piano.jpg'},
    {key: 'Kldlsldlfl', title: 'Акробаты', artist: 'Король и шут', href: 'https://storage.googleapis.com/udio-artifacts-c33fe3ba-3ffe-471f-92c8-5dfef90b3ea3/samples/74d2d87225124e6982286029a1dd444f/1/%25C3%2590%25C2%259D%25C3%2590%25C2%25BE%25C3%2591%25C2%2587%25C3%2590%25C2%25BD%25C3%2590%25C2%25BE%25C3%2590%25C2%25B9%2520%25C3%2590%25C2%25A6%25C3%2590%25C2%25B8%25C3%2591%25C2%2580%25C3%2590%25C2%25BA%2520ext%2520v1.2.1.mp3', image: 'skazki.jpg'},
    {key: 'Kldlsldlfl', title: 'Кот наркот', artist: 'Король и шут', href: 'https://storage.googleapis.com/udio-artifacts-c33fe3ba-3ffe-471f-92c8-5dfef90b3ea3/samples/70ba1e5018cd4d8cacd987e26a5b6c76/2/%25D0%259A%25D0%25B8%25D0%25A8%2520-%2520%25D0%2592%2520%25D0%25BB%25D0%25B5%25D1%2581%25D1%2583%2520%25D1%2580%25D0%25BE%25D0%25B4%25D0%25B8%25D0%25BB%25D0%25B0%25D1%2581%25D1%258C%2520%25D1%2591%25D0%25BB%25D0%25BE%25D1%2587%25D0%25BA%25D0%25B0%2520ext%2520v1.2.2.2.2.2.2.mp3', image: 'skazki.jpg'},
    {key: 'Kldlsldlfl', title: 'Вор', artist: 'Король и шут', href: 'https://storage.googleapis.com/udio-artifacts-c33fe3ba-3ffe-471f-92c8-5dfef90b3ea3/samples/b74d0b2c535c4ad5a58ea688d45f2a7f/2/%25C3%2590%25C2%259F%25C3%2591%25C2%2580%25C3%2590%25C2%25BE%25C3%2591%25C2%2589%25C3%2590%25C2%25B0%25C3%2590%25C2%25B9%252C%2520%25C3%2590%25C2%259F%25C3%2591%25C2%2580%25C3%2590%25C2%25BE%25C3%2591%25C2%2588%25C3%2590%25C2%25BB%25C3%2590%25C2%25BE%25C3%2590%25C2%25B5%2520ext%2520v2.2.2.2.1.2.mp3', image: 'skazki.jpg'},
    {key: 'Kldlsldlfl', title: 'Ария тодда', artist: 'Король и шут', href: 'https://cdn1.suno.ai/6801dfa7-5f85-488f-a802-5a1e793e7224.mp3', image: 'skazki.jpg'},
  ]

  const albums = [
    {key: 'Sfkdghddhs', title: 'Неизданные сказки', artist: 'Король и шут', image: 'skazki.jpg'},
    {key: 'Sgfgdsfdf', title: 'Music', artist: 'Neuro', image: 'piano.jpg'}
  ]

  const albumTracks = [
    {album: 'Sfkdghddhs', track: 'Kldlsldlfl'},
    {album: 'Sgfgdsfdf', track: 'Jdkksgfkfk'}
  ]

  const artists = [
    {name: 'Король и шут', image: ''},
    {name: 'DZHUZ', image: ''},
    {name: 'Миксайс', image: ''},
    {name: 'Ария', image: ''},
  ]

  const [tracksOfAlbum, setAlbumTracks] = useState([]);

  function getAlbumTracks(albumKey){
    const linkedTracks = albumTracks.filter(at => at.album === albumKey).map(at => at.track);
    return tracks.filter(track => linkedTracks.includes(track.key));
  }
  

  const [isPlaying, setIsPlaying] = useState(false);
  const [numberCurrentTrack, setNumber] = useState(0);
  const [currentTrack, updateCurrentTrack] = useState(
    {
      key: 'Kldlsldlfl', 
      title: 'Акробаты', 
      artist: 'Король и шут', 
      href: 'https://storage.googleapis.com/udio-artifacts-c33fe3ba-3ffe-471f-92c8-5dfef90b3ea3/samples/74d2d87225124e6982286029a1dd444f/1/%25C3%2590%25C2%259D%25C3%2590%25C2%25BE%25C3%2591%25C2%2587%25C3%2590%25C2%25BD%25C3%2590%25C2%25BE%25C3%2590%25C2%25B9%2520%25C3%2590%25C2%25A6%25C3%2590%25C2%25B8%25C3%2591%25C2%2580%25C3%2590%25C2%25BA%2520ext%2520v1.2.1.mp3', 
      image: 'skazki.jpg'
    }
  );
  const [playlist, setPlaylist] = useState([]);
  const audioRef = useRef(null);
  const [albumOpen, setOpenAlbum] = useState(false);
  const [album, setAlbum] = useState(null);

  const [playerOpen, setOpenPlayer] = useState(false);

  useEffect(() => {
    if(album) setAlbumTracks(getAlbumTracks(album.key));
  }, [album]);

  return (
    <>
      <section className='menu-search'>
        <div className='menu-button'>
          <MenuButton />
        </div>
        <div className='search'>
          <Search />
        </div>
      </section>

      <section className="new-albums">
        <div className="title-new-albums">Популярные альбомы</div>
        <AlbumList albums = {albums} setOpenAlbum = {setOpenAlbum} setAlbum = {setAlbum}/>
      </section>

      <section className='top-artists'>
        <div className="title-top-artists">Артисты</div> 
        <TopArtists artists = {artists}/>   
      </section>

      <header className="header">
        Плейлист {data && data.message}
      </header>

      <section className="track-list">
        {tracks.map((track,index) => (
          <Track
            setNumber = {setNumber}
            setPlaylist = {setPlaylist} 
            playlist = {tracks}
            key = {index}
            track = {track}
            playing = {isPlaying} 
            updatePlaying = {setIsPlaying}
            currentTrack = {currentTrack}
            updateCurrentTrack = {updateCurrentTrack}
            audioRef={audioRef}
            number = {index}
          />
        ))}
      </section>

      <AudioPlayer 
        setNumber = {setNumber}
        numberCurrentTrack = {numberCurrentTrack}
        playlist = {playlist}
        updateCurrentTrack = {updateCurrentTrack}
        currentTrack={currentTrack} 
        audioRef = {audioRef} 
        isPlaying = {isPlaying} 
        setIsPlaying = {setIsPlaying} 
        playerOpen = {playerOpen} 
        setOpenPlayer = {setOpenPlayer}
      />

      <MiniPlayer 
        currentTrack ={currentTrack} 
        audioRef = {audioRef} 
        isPlaying = {isPlaying} 
        setIsPlaying = {setIsPlaying} 
        setOpenPlayer = {setOpenPlayer}
      />


      <section className={'album-page ' + (albumOpen && 'album-page-on')}>
        <div className='album-page-info'>
          <button className='back-button' onClick = {backClick}>
            <BackButton />
          </button>
          {album && <img src = {require(`./image/${album.image}`)}></img>}
          
          <div className='album-page-title'>
            {album && album.title}
          </div>
          <div className='album-page-artist'>
            {album && album.artist}
          </div>
        </div>
        <section className="track-list">
        {album && tracksOfAlbum.map((track,index) => (
          <Track
            setNumber = {setNumber}
            setPlaylist = {setPlaylist} 
            playlist = {tracksOfAlbum}
            key = {index}
            number = {index}
            track = {track}
            playing = {isPlaying} 
            updatePlaying = {setIsPlaying}
            currentTrack = {currentTrack}
            updateCurrentTrack = {updateCurrentTrack}
            audioRef={audioRef}
            />
        ))}
      </section>
      </section>
    </>
    
  );
}
