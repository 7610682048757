import React from "react";
import {ReactComponent as MusicIcon} from "./musicIcon.svg";
import {ReactComponent as LikeButton} from "./like.svg";
import "./Track.css";

function MusicAnimOn({playing}){
    return(
        <div className='music-anim-track'>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
                <span className={"music-anim-track-stroke " + (!playing && 'pause-anim-track')}></span>
        </div>
    )
}

export default function Track({setNumber, setPlaylist, playlist, number, track, playing, updatePlaying, currentTrack, updateCurrentTrack, audioRef}){

    function clickTrack(href) {
      if (currentTrack.href !== href) {
        updateCurrentTrack(track);
        updatePlaying(true);
        setPlaylist(playlist);
        setNumber(number);
        
      } else {
        if (playing) {
          audioRef.current.pause();
          updatePlaying(false);
        } else {
          audioRef.current.play();
          updatePlaying(true);
        }
      }
    }
  
    return (
          <button className="track" onClick = {() => {clickTrack(track.href)}}>
            <div className="track-icon">
                {!track.image && <MusicIcon height = "60%" width = "60%"/>}
                {track.image && <img src = {require(`./image/${track.image}`)}></img>}
                {(currentTrack.href === track.href) && <MusicAnimOn playing = {playing}/>}
            </div>
            <div className="track-desc">
              <div className="track-title">
                {track.title}
              </div>
              <div className="track-artist">
                {track.artist}
              </div>
            </div>
            <div className="track-buttons">
              <button className="button-like">
                <LikeButton />
              </button>
            </div>
          </button>
    );
  }