import React, { useState, useEffect } from "react";
import './AudioPlayer.css';
import './MusicWave.css';
import {ReactComponent as Play} from "./play.svg"
import {ReactComponent as Pause} from "./pause.svg"
import {ReactComponent as NextPrev} from "./nextprev.svg"
import {ReactComponent as Down} from "./down.svg"


function AudioPlayer({setNumber, numberCurrentTrack, playlist, updateCurrentTrack, currentTrack, audioRef, isPlaying, setIsPlaying, playerOpen, setOpenPlayer}) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
    if(audioRef.current.currentTime === audioRef.current.duration){
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleSeek = (e) => {
    audioRef.current.currentTime = e.target.value;
    setCurrentTime(e.target.value);
  };

  const closePlayer = () => {
    setOpenPlayer(false);
  }

  const nextTrack = () => {
    if (numberCurrentTrack < playlist.length - 1) {
      updateCurrentTrack(playlist[numberCurrentTrack + 1]);
      setNumber(numberCurrentTrack + 1);
    } else {
      updateCurrentTrack(playlist[0]);
      setNumber(0);
    }
  };
  
  const prevTrack = () => {
    if (numberCurrentTrack > 0) {
      updateCurrentTrack(playlist[numberCurrentTrack - 1]);
      setNumber(numberCurrentTrack - 1);
    } else {
      let num = playlist.length - 1;
      updateCurrentTrack(playlist[num]);
      setNumber(num);
    }
  };

  function formatDuration(durationSeconds) {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioRef.current.duration);
      setCurrentTime(0);
    };

    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    return () => {
      audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);


  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = currentTrack.href;
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [currentTrack.href]);

  return (
    <section className={"player-card " + (playerOpen && 'player-card-on')}>
      {/*<div className="music">
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
        <span className={"bar " + (!isPlaying && "paused")}></span>
      </div>*/}

      <div className="player-track-info">
        <img className="player-track-image" src= {require(`./image/${currentTrack.image}`)}></img>
        <div className="player-track-title">
          {currentTrack.title}
        </div>
        <div className="player-track-artist">
          {currentTrack.artist}
        </div>
      </div>

      <input
        style={{
          backgroundSize: `${(currentTime) * 100 / (duration)}% 100%`
        }}
        className = "progress-bar"
        type="range"
        min="0"
        max={isNaN(duration) ? 0 : duration}
        value={currentTime}
        onChange={handleSeek}
      />

      <audio ref={audioRef} src={currentTrack.href} />

      <div className="track-duration">
        <p>{formatDuration(currentTime)}</p>
        <p>{isNaN(duration) ? '--:--' : formatDuration(duration)}</p>
      </div>

      <div className="player-control">
        <button className="prev" onClick={prevTrack}>
            <NextPrev />
        </button>
        <button className="play-pause" onClick={handlePlayPause}>
            {isPlaying ? <Pause height = "80%" width = "80%"/> : <Play height = "60%" width = "60%"/>}
        </button>
        <button className="next" onClick={nextTrack}>
            <NextPrev />
        </button>
      </div>
      <button className="close-player" onClick={closePlayer}>
        <Down height = "80%" width = "80%"/>
      </button>
    </section>
  );
}

export default AudioPlayer;