import React from "react";
import 'swiper/css';
import 'swiper/css/free-mode';
import './AlbumElem.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode} from 'swiper/modules';

export default function AlbumList({albums, setOpenAlbum, setAlbum}){
    function ClickOnAlbum(album){
      setAlbum(album);
      setOpenAlbum(true);
    }

    return(
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        freeMode={true}
        modules={[FreeMode]}
        > 
      {albums.map((album, index) => (
          <SwiperSlide key = {index}>
            <button className="album-elem" onClick = {() => {ClickOnAlbum(album)}}>
              <div className='album-photo'>
                <img src = {require(`./image/${album.image}`)}></img>
              </div>
              <div className='album-title'>
                {album.title}
              </div>
              <div className='album-artist'>
                {album.artist}
              </div>
            </button>
          </SwiperSlide>
      ))}
      </Swiper>
    )
}