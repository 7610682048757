import React from "react";
import 'swiper/css';
import 'swiper/css/free-mode';
import './TopArtists.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode} from 'swiper/modules';

export default function TopArtists({artists}){
    /*function ClickOnAlbum(album){
      setAlbum(album);
      setOpenAlbum(true);
    }*/

    return(
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        freeMode={true}
        modules={[FreeMode]}
        > 
      {artists.map((artist, index) => (
          <SwiperSlide key = {index}>
            <button className="artist-elem">
              <div className='artist-photo'>
                
              </div>
              <div className='artist-title'>
                {artist.name}
              </div>
            </button>
          </SwiperSlide>
      ))}
      </Swiper>
    )
}